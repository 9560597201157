<template>
  <div class="formulaire-info">
    <div class="la-family-container">
      <div class="la-family-text">
        <h2>Formulaire à remplir ⚠️</h2>
        <p>️La famille doit remplir un formulaire pour saisir les URLs ou une photo des profils sociaux du défunt.</p>
      </div>
      <div>
        <button v-if="etatFormulaire === '⏳ En attente de réponse'" @click="renvoyerMail"
          :disabled="isSendingMail || mailSent">
          <span v-if="isSendingMail">Chargement...</span>
          <span v-else>{{ mailSent ? 'Mail renvoyé' : 'Renvoyer un mail' }}</span>
        </button>
      </div>
    </div>
    <div class="info-section">
      <div class="email-container">
        <div class="info-item" style="width: 100%;">
          <label>Destinataire du mail envoyé</label>
          <input type="text" :value="emailPouvoir" readonly disabled />
        </div>
        <div class="info-item">
          <label>Formulaire envoyé</label>
          <button @click="ouvrirFormulaire" class="open-form-button">
            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M14 9.83333V14.8333C14 15.2754 13.8244 15.6993 13.5118 16.0118C13.1993 16.3244 12.7754 16.5 12.3333 16.5H3.16667C2.72464 16.5 2.30072 16.3244 1.98816 16.0118C1.67559 15.6993 1.5 15.2754 1.5 14.8333V5.66667C1.5 5.22464 1.67559 4.80072 1.98816 4.48816C2.30072 4.17559 2.72464 4 3.16667 4H8.16667M11.5 1.5H16.5M16.5 1.5V6.5M16.5 1.5L7.33333 10.6667"
                stroke="#475467" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round" />
            </svg>

            Accéder au formulaire
          </button>
        </div>
      </div>
      <!-- <div class="info-item">
        <label>Statut du formulaire</label>
        <span :class="{'status': true, 'waiting': etatFormulaire === '⏳ En attente de réponse'}">{{ etatFormulaire }}</span>
      </div> -->
    </div>
  </div>
</template>

<script>
export default {
  name: 'FormulaireInfo',
  props: {
    emailPouvoir: {
      type: String,
      required: true
    },
    tallyLink: {
      type: String,
      required: true
    },
    etatFormulaire: {
      type: String,
      required: true
    },
    isSendingMail: {
      type: Boolean,
      required: true
    },
    mailSent: {
      type: Boolean,
      required: true
    }
  },
  methods: {
    renvoyerMail() {
      this.$emit('renvoyer-mail');
    },
    ouvrirFormulaire() {
      window.open(this.tallyLink, '_blank');
    }
  }
};
</script>

<style scoped>
.formulaire-info {
  border: 1px solid #EAECF0;
  padding: 19px;
  background-color: #FFFFFF;
  /* max-width: 600px; */
  margin: 0 auto;

  @media (min-width: 1000px) {
    width: 758px;
  }
}

h2 {
  font-size: 1.5em;
  margin-bottom: 4px;
  margin-top: 0px;
  font-weight: 500;
}

p {
  margin-bottom: 30px;
  margin-top: 0px;
  color: #344054;
  font-size: 16px;
}

.info-section {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.info-item {
  display: flex;
  flex-direction: column;
}

label {
  font-weight: normal;
  margin-bottom: 5px;
  color: #344054;
  font-size: 14px;
}

input[type="text"] {
  padding: 13px 10px;
  border: 1px solid #D0D5DD;
  border-radius: 5px;
  background-color: #F9FAFB;
  color: #333;
}

.status {
  display: inline-block;
  padding: 5px 10px;
  border-radius: 5px;
  background-color: #f9f9f9;
  color: #333;
}

.status.waiting {
  background-color: #fff3cd;
  color: #856404;
  border: 1px solid #ffeeba;
}

button {
  padding: 10px 20px;
  background-color: #2E90FA;
  color: white;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  width: 230px;
  font-weight: 500;
  font-family: 'Outfit', sans-serif;
  font-size: 16px;
}

button:disabled {
  background-color: #53B1FD;
  cursor: not-allowed;
}

button:hover:not(:disabled) {
  background-color: #1570EF;
}

.la-family-container {
  display: flex;
  column-gap: 30px;
}

.la-family-text {
  max-width: 492px;
}

.open-form-button {
  display: flex;
  align-items: center;
  padding: 11px 20px;
  background-color: #F9FAFB;
  color: #344054;
  border: 1px solid #D0D5DD;
  border-radius: 6px;
  cursor: pointer;
  font-weight: 500;
  font-family: 'Outfit', sans-serif;
  font-size: 16px;
}

.open-form-button svg {
  margin-right: 8px;
}

.open-form-button:hover {
  background-color: #EAECF0 !important;
}

.email-container {
  display: flex;
  column-gap: 18px;
}
</style>
