<template>
  <div class="complement-info">
    <div class="commentair-container">
      <div>
        <h2>Commentaires de Repos Digital</h2>
        <div class="alert">
          {{ commentsRd }}
        </div>
      </div>
    </div>
    <div class="commentair-container" style="margin-top: 20px;">
      <div class="header documents-button">
        <div>
          <h2>Documents</h2>
          <p>Insérez les documents ci-dessous :</p>
        </div>
        <button @click="modifierDocuments" :disabled="isLoadingDocuments || !allFilesUploaded" class="modify-button">
          {{ isLoadingDocuments ? 'Chargement...' : 'Envoyer les documents' }}
        </button>
      </div>
 

    <div class="documents">
      <div v-if="isDocumentManquant('Acte de décès')" class="document-item">
        <label class="acte-des">Acte de décès</label>
        <div class="actions-header">
          <button @click="$refs.Joindre.click">
            <svg width="12" height="16" viewBox="0 0 12 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M1.5243 5.63205L4.13488 2.78276C4.89048 1.96125 5.91355 1.5 6.98008 1.5C8.0466 1.5 9.06967 1.96125 9.82528 2.78276C10.5776 3.60623 11 4.72165 11 5.88451C11 7.04738 10.5776 8.1628 9.82528 8.98627L5.57565 13.6245C5.32924 13.9015 5.03439 14.1217 4.70858 14.2721C4.38276 14.4225 4.03261 14.5 3.67885 14.5C3.3251 14.5 2.97494 14.4225 2.64913 14.2721C2.32331 14.1217 2.02846 13.9015 1.78205 13.6245C1.2812 13.0756 1 12.3325 1 11.5578C1 10.7832 1.2812 10.0401 1.78205 9.4912L6.23656 4.62939C6.35976 4.49087 6.50719 4.38076 6.67009 4.30556C6.833 4.23037 7.00808 4.19162 7.18496 4.19162C7.36183 4.19162 7.53691 4.23037 7.69982 4.30556C7.86273 4.38076 8.01015 4.49087 8.13336 4.62939C8.26027 4.76386 8.36116 4.92476 8.43005 5.10257C8.49895 5.28037 8.53445 5.47146 8.53445 5.66451C8.53445 5.85756 8.49895 6.04864 8.43005 6.22645C8.36116 6.40425 8.26027 6.56515 8.13336 6.69963L3.50702 11.749"
                stroke="#344054" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
            Joindre un fichier</button>
        </div>
        <input ref="Joindre" style="display: none;" type="file" @change="handleFileUpload('acteDeDeces', $event)"
          class="file-input" />
        <div v-if="uploadedFiles.acteDeDeces" class="file-preview">
          <template v-if="isPdf(uploadedFiles.acteDeDeces)">
            <embed :src="getObjectURL(uploadedFiles.acteDeDeces)" width="100%" height="400px" />
          </template>
          <template v-else>
            <img :src="getObjectURL(uploadedFiles.acteDeDeces)" width="100%" height="400px" />
          </template>
          <div class="file-info">
            <span>{{ uploadedFiles.acteDeDeces.name }}</span>
            <button @click="removeFile('acteDeDeces')" class="remove-button">🗑️</button>
          </div>
        </div>
      </div>
      <div v-if="isDocumentManquant('CNI pouvoir')" class="document-item">
        <label>Carte d'identité du pouvoir</label>
        <div class="actions-header">
          <button @click="$refs.carte.click">
            <svg width="12" height="16" viewBox="0 0 12 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M1.5243 5.63205L4.13488 2.78276C4.89048 1.96125 5.91355 1.5 6.98008 1.5C8.0466 1.5 9.06967 1.96125 9.82528 2.78276C10.5776 3.60623 11 4.72165 11 5.88451C11 7.04738 10.5776 8.1628 9.82528 8.98627L5.57565 13.6245C5.32924 13.9015 5.03439 14.1217 4.70858 14.2721C4.38276 14.4225 4.03261 14.5 3.67885 14.5C3.3251 14.5 2.97494 14.4225 2.64913 14.2721C2.32331 14.1217 2.02846 13.9015 1.78205 13.6245C1.2812 13.0756 1 12.3325 1 11.5578C1 10.7832 1.2812 10.0401 1.78205 9.4912L6.23656 4.62939C6.35976 4.49087 6.50719 4.38076 6.67009 4.30556C6.833 4.23037 7.00808 4.19162 7.18496 4.19162C7.36183 4.19162 7.53691 4.23037 7.69982 4.30556C7.86273 4.38076 8.01015 4.49087 8.13336 4.62939C8.26027 4.76386 8.36116 4.92476 8.43005 5.10257C8.49895 5.28037 8.53445 5.47146 8.53445 5.66451C8.53445 5.85756 8.49895 6.04864 8.43005 6.22645C8.36116 6.40425 8.26027 6.56515 8.13336 6.69963L3.50702 11.749"
                stroke="#344054" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
            Joindre un fichier</button>
        </div>
        <input ref="carte" style="display: none;" type="file" @change="handleFileUpload('carteIdentitePouvoir', $event)" class="file-input" />
        <div v-if="uploadedFiles.carteIdentitePouvoir" class="file-preview">
          <template v-if="isPdf(uploadedFiles.carteIdentitePouvoir)">
            <embed :src="getObjectURL(uploadedFiles.carteIdentitePouvoir)" width="100%" height="400px" />
          </template>
          <template v-else>
            <img :src="getObjectURL(uploadedFiles.carteIdentitePouvoir)" width="100%" height="400px" />
          </template>
          <div class="file-info">
            <span>{{ uploadedFiles.carteIdentitePouvoir.name }}</span>
            <button @click="removeFile('carteIdentitePouvoir')" class="remove-button">🗑️</button>
          </div>
        </div>
      </div>
      <div v-if="isDocumentManquant('Preuve de lien')" class="document-item">
        <label>Preuve de lien de parenté</label>
        <div class="actions-header">
          <button @click="$refs.preuve.click">
            <svg width="12" height="16" viewBox="0 0 12 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M1.5243 5.63205L4.13488 2.78276C4.89048 1.96125 5.91355 1.5 6.98008 1.5C8.0466 1.5 9.06967 1.96125 9.82528 2.78276C10.5776 3.60623 11 4.72165 11 5.88451C11 7.04738 10.5776 8.1628 9.82528 8.98627L5.57565 13.6245C5.32924 13.9015 5.03439 14.1217 4.70858 14.2721C4.38276 14.4225 4.03261 14.5 3.67885 14.5C3.3251 14.5 2.97494 14.4225 2.64913 14.2721C2.32331 14.1217 2.02846 13.9015 1.78205 13.6245C1.2812 13.0756 1 12.3325 1 11.5578C1 10.7832 1.2812 10.0401 1.78205 9.4912L6.23656 4.62939C6.35976 4.49087 6.50719 4.38076 6.67009 4.30556C6.833 4.23037 7.00808 4.19162 7.18496 4.19162C7.36183 4.19162 7.53691 4.23037 7.69982 4.30556C7.86273 4.38076 8.01015 4.49087 8.13336 4.62939C8.26027 4.76386 8.36116 4.92476 8.43005 5.10257C8.49895 5.28037 8.53445 5.47146 8.53445 5.66451C8.53445 5.85756 8.49895 6.04864 8.43005 6.22645C8.36116 6.40425 8.26027 6.56515 8.13336 6.69963L3.50702 11.749"
                stroke="#344054" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
            Joindre un fichier</button>
        </div>
        <input ref="preuve" style="display: none;" type="file" @change="handleFileUpload('preuveLienParente', $event)" class="file-input" />
        <div v-if="uploadedFiles.preuveLienParente" class="file-preview">
          <template v-if="isPdf(uploadedFiles.preuveLienParente)">
            <embed :src="getObjectURL(uploadedFiles.preuveLienParente)" width="100%" height="400px" />
          </template>
          <template v-else>
            <img :src="getObjectURL(uploadedFiles.preuveLienParente)" width="100%" height="400px" />
          </template>
          <div class="file-info">
            <span>{{ uploadedFiles.preuveLienParente.name }}</span>
            <button @click="removeFile('preuveLienParente')" class="remove-button">🗑️</button>
          </div>
        </div>
      </div>
      <div v-if="isDocumentManquant('Acte notarié')" class="document-item">
        <label>Acte Notarié</label>
        <div class="actions-header">
          <button @click="$refs.acte.click">
            <svg width="12" height="16" viewBox="0 0 12 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M1.5243 5.63205L4.13488 2.78276C4.89048 1.96125 5.91355 1.5 6.98008 1.5C8.0466 1.5 9.06967 1.96125 9.82528 2.78276C10.5776 3.60623 11 4.72165 11 5.88451C11 7.04738 10.5776 8.1628 9.82528 8.98627L5.57565 13.6245C5.32924 13.9015 5.03439 14.1217 4.70858 14.2721C4.38276 14.4225 4.03261 14.5 3.67885 14.5C3.3251 14.5 2.97494 14.4225 2.64913 14.2721C2.32331 14.1217 2.02846 13.9015 1.78205 13.6245C1.2812 13.0756 1 12.3325 1 11.5578C1 10.7832 1.2812 10.0401 1.78205 9.4912L6.23656 4.62939C6.35976 4.49087 6.50719 4.38076 6.67009 4.30556C6.833 4.23037 7.00808 4.19162 7.18496 4.19162C7.36183 4.19162 7.53691 4.23037 7.69982 4.30556C7.86273 4.38076 8.01015 4.49087 8.13336 4.62939C8.26027 4.76386 8.36116 4.92476 8.43005 5.10257C8.49895 5.28037 8.53445 5.47146 8.53445 5.66451C8.53445 5.85756 8.49895 6.04864 8.43005 6.22645C8.36116 6.40425 8.26027 6.56515 8.13336 6.69963L3.50702 11.749"
                stroke="#344054" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
            Joindre un fichier</button>
        </div>
        <input ref="acte" style="display: none" type="file" @change="handleFileUpload('acteNotarie', $event)" class="file-input" />
        <div v-if="uploadedFiles.acteNotarie" class="file-preview">
          <template v-if="isPdf(uploadedFiles.acteNotarie)">
            <embed :src="getObjectURL(uploadedFiles.acteNotarie)" width="100%" height="400px" />
          </template>
          <template v-else>
            <img :src="getObjectURL(uploadedFiles.acteNotarie)" width="100%" height="400px" />
          </template>
          <div class="file-info">
            <span>{{ uploadedFiles.acteNotarie.name }}</span>
            <button @click="removeFile('acteNotarie')" class="remove-button">🗑️</button>
          </div>
        </div>
      </div>
      <div v-if="isDocumentManquant('Mandat')" class="document-item">
        <label>Mandat</label>
        <div class="actions-header">
          <button @click="$refs.mandat.click">
            <svg width="12" height="16" viewBox="0 0 12 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M1.5243 5.63205L4.13488 2.78276C4.89048 1.96125 5.91355 1.5 6.98008 1.5C8.0466 1.5 9.06967 1.96125 9.82528 2.78276C10.5776 3.60623 11 4.72165 11 5.88451C11 7.04738 10.5776 8.1628 9.82528 8.98627L5.57565 13.6245C5.32924 13.9015 5.03439 14.1217 4.70858 14.2721C4.38276 14.4225 4.03261 14.5 3.67885 14.5C3.3251 14.5 2.97494 14.4225 2.64913 14.2721C2.32331 14.1217 2.02846 13.9015 1.78205 13.6245C1.2812 13.0756 1 12.3325 1 11.5578C1 10.7832 1.2812 10.0401 1.78205 9.4912L6.23656 4.62939C6.35976 4.49087 6.50719 4.38076 6.67009 4.30556C6.833 4.23037 7.00808 4.19162 7.18496 4.19162C7.36183 4.19162 7.53691 4.23037 7.69982 4.30556C7.86273 4.38076 8.01015 4.49087 8.13336 4.62939C8.26027 4.76386 8.36116 4.92476 8.43005 5.10257C8.49895 5.28037 8.53445 5.47146 8.53445 5.66451C8.53445 5.85756 8.49895 6.04864 8.43005 6.22645C8.36116 6.40425 8.26027 6.56515 8.13336 6.69963L3.50702 11.749"
                stroke="#344054" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
            Joindre un fichier</button>
        </div>
        <input ref="mandat" style="display: none;" type="file" @change="handleFileUpload('mandat', $event)" class="file-input" />
        <div v-if="uploadedFiles.mandat" class="file-preview">
          <template v-if="isPdf(uploadedFiles.mandat)">
            <embed :src="getObjectURL(uploadedFiles.mandat)" width="100%" height="400px" />
          </template>
          <template v-else>
            <img :src="getObjectURL(uploadedFiles.mandat)" width="100%" height="400px" />
          </template>
          <div class="file-info">
            <span>{{ uploadedFiles.mandat.name }}</span>
            <button @click="removeFile('mandat')" class="remove-button">🗑️</button>
          </div>
        </div>
      </div>
    </div>
  </div>
  </div>
</template>

<script>
export default {
  name: 'ComplementInfo',
  props: {
    commentsRd: {
      type: String,
      required: true
    },
    isLoadingDocuments: {
      type: Boolean,
      required: true
    },
    uploadedFiles: {
      type: Object,
      required: true
    },
    isDocumentManquant: {
      type: Function,
      required: true
    },
    handleFileUpload: {
      type: Function,
      required: true
    },
    isPdf: {
      type: Function,
      required: true
    },
    getObjectURL: {
      type: Function,
      required: true
    },
    removeFile: {
      type: Function,
      required: true
    },
    modifierDocuments: {
      type: Function,
      required: true
    }
  },
  computed: {
    allFilesUploaded() {
      const requiredDocuments = ['Acte de décès', 'CNI pouvoir', 'Preuve de lien', 'Acte notarié', 'Mandat'];
      return requiredDocuments.every(doc => !this.isDocumentManquant(doc) || this.uploadedFiles[this.getFileKey(doc)]);
    }
  },
  methods: {
    getFileKey(documentName) {
      const mapping = {
        'Acte de décès': 'acteDeDeces',
        'CNI pouvoir': 'carteIdentitePouvoir',
        'Preuve de lien': 'preuveLienParente',
        'Acte notarié': 'acteNotarie',
        'Mandat': 'mandat'
      };
      return mapping[documentName];
    }
  }
};
</script>

<style scoped>
.commentair-container {
  padding: 19px;
  border: 1px solid #EAECF0;
  background-color: #fff;
  border-radius: 8px;
  background-color: #fff;
  max-width: 758px;
  /* Ajouter une largeur maximale pour éviter qu'il ne devienne trop large */
  margin: 0 auto;

  /* Centrer le conteneur horizontalement */
  @media (min-width: 1000px) {
    width: 758px;
    max-width: 758px;
  }
}

.dragger{
  background-color: #F9FAFB;
  border: 1px solid #D0D5DD;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-block: 15px;
  border-radius: 6px;
  column-gap: 15px;
  color: #98A2B3;
  margin-top: 6px;
  cursor: pointer;
}

.dragger span{
  color: #1570EF;
}

.alert {
  background-color: #EFF8FF;
  border: 1px solid #84CAFF;
  color: #2E90FA;
  padding: 10px 14px;
  border-radius: 6px;
  font-family: 'Outfit', serif;
  margin-top: 30px;
  font-weight: normal;
}

.commentair-container h2 {
  font-size: 24px;
  margin-bottom: 4px;
  margin-top: 0px;
  font-weight: 500;
  /* Réduire l'épaisseur de la police */
  color: #101828;
}

.documents-button button {
  padding: 10px 20px;
  background-color: #2E90FA;
  color: white;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  width: 230px;
  font-weight: 500;
  font-family: 'Outfit', sans-serif;
  font-size: 16px;
}

.documents-button button:disabled {
  background-color: #53B1FD;
  cursor: not-allowed;
}

.documents-button button:hover:not(:disabled) {
  background-color: #1570EF;
}

.acte-des {
  color: #344054;
  font-size: 14px;
}

label {
  color: #344054;
  font-size: 14px;
}

.actions-header {
  display: flex;
  /* justify-content: space-between; */
  margin-bottom: 20px;
}

.actions-header button {
  margin-top: 6px;
  width: 165px;
  padding: 12px 16px;
  background-color: white;
  color: 344054;
  border: 1px solid #D0D5DD;
  border-radius: 6px;
  cursor: pointer;
  font-size: 14px;
  display: flex;
  font-weight: 500;
  column-gap: 8px;
  font-family: 'Outfit', sans-serif;
}

.actions-header button:hover{
  background-color: #F9FAFB;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header p {
  margin: 0;
  font-size: 14px;
  color: #344054;
}

.modify-button {
  background-color: #d32f2f;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
}

.modify-button:disabled {
  background-color: #D0D5DD !important;
  cursor: not-allowed;
}

.comment-input {
  width: 100%;
  padding: 10px;
  border: 1px solid #d1d5db;
  border-radius: 5px;
  background-color: #f9fafb;
  color: #6b7280;
  font-size: 16px;
}

.documents {
  margin-top: 20px;
}

.document-item {
  margin-bottom: 20px;
}

.file-input {
  display: block;
  margin-top: 10px;
}

.file-preview {
  margin-top: 10px;
  border: 1px solid #d1d5db;
  border-radius: 5px;
  padding: 10px;
  background-color: #f9fafb;
}

.file-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
}

.remove-button {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 16px;
}
</style>
