<template>
  <div class="comment-form-container">
    <h2>Commentaires</h2>
    <div class="form-group">
      <label>Avez-vous des commentaires à transmettre à Repos Digital ?</label>
      <div class="comment-input">
        <textarea v-model="localComments" @input="updateComments" placeholder="Ajouter un commentaire"
          :readonly="isReadOnly"></textarea>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CommentForm',
  props: {
    comments: {
      type: String,
      required: true,
      default: 'sdfsdfsdf'
    },
    isReadOnly: {
      type: Boolean,
      required: true
    }
  },
  data(nd) {
    return {
      localComments: nd.comments
    }
  },
  watch: {
    comments(newVal) {
      this.localComments = newVal;
    }
  },
  methods: {
    updateComments() {
      this.$emit('update-comments', this.localComments);
    }
  }
}
</script>

<style scoped>
.form-group {
  margin-bottom: 20px;
}

.comment-form-container{
  padding: 19px;
  border: 1px solid #EAECF0;
  background-color: #fff;
  max-width: 1200px; /* Ajouter une largeur maximale pour éviter qu'il ne devienne trop large */
  margin: 0 auto; /* Centrer le conteneur horizontalement */
  @media (min-width: 1000px) {
    width: 758px;
  }
}

h2 {
  font-size: 24px;
  margin-bottom: 30px;
  margin-top: 0px;
  font-weight: 500; /* Réduire l'épaisseur de la police */
  color: #101828;
}

label {
  display: block;
  margin-bottom: 1px;
  color: #344054;
  font-weight: 400;
  font-size: 14px; /* Réduire la taille de la police des labels */
}

textarea {
  width: 100%;
  padding: 10px;
  margin-top: 5px;
  border: 1px solid #ccc;
  border-radius: 6px;
  font-size: 14px;
  font-family: 'Outfit', sans-serif;
  /* Assurer que la bonne police est utilisée */
  height: 100px;
}

textarea::placeholder{
  color: #D0D5DD !important;
  padding: 10px;
}

.comment-input{
  display: flex;
  flex: 1;
}
</style>
