<template>
  <div class="info-form-container">
    <h2>Informations des comptes du défunt</h2>
    <form>
      <div class="form-group">
        <label>Adresse(s) email du défunt</label>
        <div class="email-input">
          <input type="text" v-model="localEmail" readonly disabled />
        </div>
      </div>
      <div class="form-group">
        <label>Numéro de téléphone du défunt</label>
        <div class="phone-input">
          <input type="text" v-model="localPhone" placeholder="06 20 80 90 12" readonly disabled />
        </div>
      </div>
      <label>Commentaires transmis à Repos Digital</label>
      <div class="comment-input">
        <textarea v-model="localComments" readonly disabled></textarea>
      </div>
    </form>
  </div>
</template>

<script>
export default {
  name: 'InfoFormReadOnly',
  props: {
    email: {
      type: String,
      required: true
    },
    phone: {
      type: String,
      required: true
    },
    comments: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      localEmail: this.email,
      localPhone: this.phone,
      localComments: this.comments
    }
  },
  watch: {
    email(newVal) {
      this.localEmail = newVal;
    },
    phone(newVal) {
      this.localPhone = newVal;
    },
    comments(newVal) {
      this.localComments = newVal;
    }
  }
}
</script>

<style scoped>
.info-form-container {
  padding: 20px;
  border: 1px solid #EAECF0;
  /* border-radius: 10px; */
  background-color: #fff;
  /* box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); */
  width: 80%; /* Définir la largeur à 80% de l'espace horizontal disponible */
  max-width: 1200px; /* Ajouter une largeur maximale pour éviter qu'il ne devienne trop large */
  margin: 0 auto; /* Centrer le conteneur horizontalement */
  @media (min-width: 1000px) {
    min-width: 757px;
  }
}

h2 {
  font-size: 24px;
  margin-bottom: 30px;
  margin-top: 0px;
  font-weight: 500; /* Réduire l'épaisseur de la police */
  color: #333;
}

form {
  display: flex;
  flex-direction: column;
}

.form-group {
  margin-bottom: 20px;
}

label {
  display: block;
  font-weight: normal;
  font-size: 14px; /* Réduire la taille de la police des labels */
  margin-bottom: 2px;
  color: #344054;

}

label span {
  font-weight: normal;
  color: #98A2B3; /* Changer la couleur du texte */
}

input, textarea {
  padding: 12px 10px;
  width: 100%;
  margin-top: 5px;
  border: 1px solid #ccc;
  border-radius: 6px;
  font-size: 14px;
  font-family: 'Outfit', sans-serif; /* Assurer que la bonne police est utilisée */
}

.phone-input {
  display: flex;
  align-items: center;
}

.phone-input select {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px 0 0 5px;
  background-color: #f9f9f9;
  flex: 0 0 100px; /* Fixer la largeur du select */
}

.phone-input input {
  flex: 1;
  border-radius: 6px;
  border: 1px solid #ccc;
  padding: 12px 10px;
}

.email-input{
  display: flex;
  flex: 1;
}

button {
  padding: 10px 20px;
  margin-top: 10px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

button:hover:enabled {
  background-color: #0056b3;
}

.comment-input{
  display: flex;
  flex: 1;
}

</style>
