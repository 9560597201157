<template>
  <div class="popup-overlay" v-if="show">
    <div class="popup-content">
      <img :src="account.logo" alt="logo" class="account-logo" />
      <h2>{{ account.name }}</h2>
      <p class="social-text">Réseau social</p>
      <label>Adresse mail ou numéro associé au compte</label>
      <div class="adress-input">
        <input type="text" v-model="email" name="address" />
      </div>
      <div class="option-select">
        <label>Action sur le compte</label>
        <div class="select-container">
          <select v-model="action">
            <option disabled value="">Sélectionner une action sur le compte</option>
            <option value="Suppression">Suppression du compte</option>
            <option value="Conservation">Conservation du compte</option>
            <option v-if="isTransformable(account.name)" value="Transformation">Transformation en compte commémoratif
            </option>
          </select>
        </div>
      </div>
      <div class="popup-footer">
        <button @click="$emit('back')">Retour</button>
        <button @click="addAccount" :disabled="!email || !action">Ajouter le compte</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AccountDetailsPopup',
  props: {
    show: {
      type: Boolean,
      required: true
    },
    account: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      email: '',
      action: ''
    };
  },
  methods: {
    addAccount() {
      this.$emit('add', {
        ...this.account,
        email: this.email,
        action: this.action,
        nom: this.account.name // Assurez-vous que le nom est transmis
      });
    },
    isTransformable(name) {
      return ['Facebook', 'Instagram', 'LinkedIn'].includes(name);
    }
  }
};
</script>

<style scoped>
.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.popup-content {
  background-color: white;
  padding: 24px;
  border-radius: 10px;
  width: 400px;
  max-width: 90%;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.popup-content h2 {
  font-size: 20px;
  color: #030424;
  font-weight: 600;
  margin-top: 10px;
  margin-bottom: 0px;
}

.account-logo {
  width: 48px;
  height: 48px;
  display: block;
}

.social-text {
  margin-top: 4px;
  margin-bottom: 20px;
  font-size: 14px;
  color: #667085;
}

.adress-input {
  display: flex;
  flex: 1;
}

input,
select {
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

select {
  border: transparent;
  border-right: 10px solid transparent;
  margin-bottom: 0px;
}

.popup-footer {
  margin-top: 30px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 12px;
}

button {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-family: 'Outfit', serif;
}

button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

button:first-of-type {
  background-color: #ffffff;
  border: 1px solid #D0D5DD;
  border-radius: 6px;
  font-size: 14px;
  color: #344054;
  font-weight: 500;
}

button:first-of-type:hover {
  background-color: #F9FAFB;

}

button:last-of-type {
  background-color: #2E90FA;
  color: white;
}

button:last-of-type:hover {
  background-color: #1570EF;
}

label {
  display: block;
  margin-bottom: 7px;
  color: #344054;
  font-weight: 400;
  font-size: 14px;
  /* Réduire la taille de la police des labels */
}

.option-select {
  margin-top: 11px;
}

.select-container {
  border: 1px solid #D0D5DD;
  border-radius: 6px;
}
</style>
