// src/main.js
import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import { logErrorToDiscord } from './logService';

const app = createApp(App);

app.config.errorHandler = (err, vm, info) => {
  const errorDetails = `
  **Vue error:** ${err.toString()}
  **Component:** ${vm.$options.name || 'anonymous'}
  **Info:** ${info}
  `;
  console.error(errorDetails);
  logErrorToDiscord(errorDetails);
};

window.addEventListener('error', (event) => {
  const errorDetails = `
  **Global error:** ${event.message}
  **Source:** ${event.filename}
  **Line:** ${event.lineno}
  **Column:** ${event.colno}
  `;
  console.error(errorDetails);
  logErrorToDiscord(errorDetails);
});

window.addEventListener('unhandledrejection', (event) => {
  const errorDetails = `
  **Unhandled Promise Rejection:** ${event.reason}
  `;
  console.error(errorDetails);
  logErrorToDiscord(errorDetails);
});

app.use(router).mount('#app');
