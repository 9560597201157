<template>
  <div class="popup-overlay" v-if="show">
    <div class="popup-content">
      <div>
        <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect width="48" height="48" rx="24" fill="#EFF8FF" />
          <path
            d="M34 24C34 29.5228 29.5228 34 24 34M34 24C34 18.4772 29.5228 14 24 14M34 24H14M24 34C18.4772 34 14 29.5228 14 24M24 34C26.5013 31.2616 27.9228 27.708 28 24C27.9228 20.292 26.5013 16.7384 24 14M24 34C21.4987 31.2616 20.0772 27.708 20 24C20.0772 20.292 21.4987 16.7384 24 14M14 24C14 18.4772 18.4772 14 24 14"
            stroke="#2E90FA" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
      </div>
      <div class="popup-header">
        <h2>Ajouter un compte</h2>
      </div>
      <p class="choice-text">Choisissez un compte ci-dessous pour l'ajouter à la liste des comptes appartenant au défunt.</p>
      <!-- <div class="search-container">
        <input type="text" v-model="search" placeholder="Rechercher un compte à ajouter" />
      </div> -->
      <div class="recherche-input " style="position: relative;">
        <input type="text" v-model="search" placeholder="Rechercher un compte à ajouter" class="search-input" />
        <div class="icon">
          <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M17.5 17.5L13.875 13.875M15.8333 9.16667C15.8333 12.8486 12.8486 15.8333 9.16667 15.8333C5.48477 15.8333 2.5 12.8486 2.5 9.16667C2.5 5.48477 5.48477 2.5 9.16667 2.5C12.8486 2.5 15.8333 5.48477 15.8333 9.16667Z"
              stroke="#667085" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round" />
          </svg>
        </div>
      </div>
      <div class="account-list">
        <div v-for="account in filteredAccounts" :key="account.id" @click="selectAccount(account)" :class="{ selected: selectedAccount && selectedAccount.id === account.id }">
          <img :src="account.logo" alt="logo" class="compte-logo" />
          <span>{{ account.name }}</span>
          <img v-if="selectedAccount && selectedAccount.id === account.id" :src="checkIcon" alt="check" class="checkmark" />
        </div>
      </div>
      <div class="popup-footer">
        <button @click="close">Annuler</button>
        <button @click="nextStep" :disabled="!selectedAccount">Suivant</button>
      </div>
    </div>
  </div>
</template>

<script>
import checkIcon from '@/assets/check.png';

export default {
  name: 'AddAccountPopup',
  props: {
    show: {
      type: Boolean,
      required: true
    },
    accounts: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      search: '',
      selectedAccount: null,
      checkIcon
    };
  },
  computed: {
    filteredAccounts() {
      return this.accounts.filter(account => account.name && account.name.toLowerCase().includes(this.search.toLowerCase()));
    }
  },
  methods: {
    selectAccount(account) {
      this.selectedAccount = account;
    },
    close() {
      this.$emit('close');
    },
    nextStep() {
      this.$emit('next', this.selectedAccount);
    }
  }
};
</script>

<style scoped>
.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.popup-content {
  background-color: white;
  padding: 24px;
  border-radius: 10px;
  width: 400px;
  max-width: 90%;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.popup-header {
  display: flex;
  align-items: center;
  margin-bottom: 0px;
  margin-top: 0px;
}

.popup-header h2{
  font-size: 20px;
  color: #030424;
  font-weight: 500;
  margin-top: 10px;
  margin-bottom: 0px;
}

.choice-text{
  margin-top: 4px;
  font-size: 14px;
  color: #667085; 
  font-weight: normal;
}

.search-container {
  margin-bottom: 20px;
}

.recherche-input {
  display: flex;
  flex: 1;
  position: relative;
}

.search-input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 6px;
  padding-left: 40px; /* Adjust padding to make space for the icon */
}

.icon {
  position: absolute;
  top: 50%; /* Centrer verticalement */
  left: 10px;
  transform: translateY(-50%); /* Centrer verticalement */
}

.account-list {
  max-height: 200px;
  overflow-y: auto;
  margin: 20px 0;
}

.account-list div {
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 10px;
  position: relative;
  border-radius: 8px;
}

.account-list div:hover,
.account-list div.selected {
  background-color: #EFF8FF;
}

.account-list img {
  width: 24px;
  height: 24px;
  margin-right: 10px;
}

.account-list span {
  flex-grow: 1;
  font-size: 16px;
  color: #101828;
}

.checkmark {
  color: #2E90FA;
  font-size: 18px;
  position: absolute;
  right: 10px;
}

.popup-footer {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 12px;
}

button {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-family: 'Outfit', serif;
}

button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

button:first-of-type {
  background-color: #ffffff;
  border: 1px solid #D0D5DD;
  border-radius: 6px;
  font-size: 14px;
  color: #344054;
  font-weight: 500;
}

button:first-of-type:hover {
  background-color: #F9FAFB;
}

button:last-of-type {
  background-color: #2E90FA;
  color: white;
}

button:last-of-type:hover{
  background-color: #1570EF;
}

.recherche-input {
  display: flex;
  flex: 1;
}

.search-input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 6px;
}

.icon {
  position: absolute;
  top: 50%; /* Centrer verticalement */
  left: 10px;
  transform: translateY(-50%); /* Centrer verticalement */
}

input {
  padding: 13px 10px !important;
  padding-left: 39px !important;
}
.compte-logo {
  width: 30px;
  height: 30px;
  margin-right: 10px;
  border-radius: 50%;
  object-fit: contain;
}
</style>
