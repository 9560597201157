// src/logService.js
export async function logErrorToDiscord(message) {
    const webhookUrl = 'https://discord.com/api/webhooks/1247186929137487933/Ak9vs_R6RUK2ANjqE3ID-Xr8tAE6N6ptl0tkjqqu4YUbavMJekxjutJ5SQQcP24Vnigk';
  
    const payload = {
      content: message,
    };
  
    try {
      const response = await fetch(webhookUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      });
  
      if (!response.ok) {
        console.error('Error logging to Discord:', response.statusText);
      }
    } catch (error) {
      console.error('Error logging to Discord:', error);
    }
  }
  