<template>
  <div>
    <div class="diagnostic-container">
      <div v-if="this.registeredComptesLocal.length > 0 || this.unregisteredComptesLocal.length > 0">
        <div class="recap-container">
          <div class="recap-text">
            <h2 style="margin-bottom: 0px;">Récapitulatif des démarches</h2>
            <p style="margin-top: 4px;">Récapitulatif de toutes les actions qui vont être menées sur les comptes de {{
        fullName }}</p>
          </div>
          <div class="recap-button">
            <button v-if="dossierState === 'En attente de lancement'" @click="$emit('lancer-demarches')"
              :disabled="scanEnCours || isLoading" :class="{ 'loading-button': isLoading }">
              <span v-if="isLoading">
                <!-- Chargement... {{ completedRequests }} / {{ totalRequests }} -->
                <div class="progress-bar">
                  <div class="progress" :style="{ width: (completedRequests / totalRequests * 100) + '%' }"></div>
                </div>
              </span>
              <span v-else>Lancer les démarches</span>
            </button>
          </div>
        </div>

        <div class="instructions">
          <p>
            L'option de "<strong>Transformation</strong>" est disponible uniquement pour Facebook, Instagram et
            LinkedIn.
          </p>
          <p>
            L'option de "<strong>Conservation</strong>" est déconseillée, sauf pour une adresse mail partagée.
          </p>
        </div>

        <div class="actions-header" style="position: relative;">
          <div style="display: flex; flex: 1;">
            <input type="text" v-model="searchQuery" placeholder="Recherchez un compte" />
          </div>
          <div class="icon">
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M17.5 17.5L13.875 13.875M15.8333 9.16667C15.8333 12.8486 12.8486 15.8333 9.16667 15.8333C5.48477 15.8333 2.5 12.8486 2.5 9.16667C2.5 5.48477 5.48477 2.5 9.16667 2.5C12.8486 2.5 15.8333 5.48477 15.8333 9.16667Z"
                stroke="#667085" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
          </div>
          <button @click="showAddAccountPopup"> <svg width="20" height="20" viewBox="0 0 20 20" fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <path
                d="M10.0013 6.66406V13.3307M6.66797 9.9974H13.3346M18.3346 9.9974C18.3346 14.5998 14.6037 18.3307 10.0013 18.3307C5.39893 18.3307 1.66797 14.5998 1.66797 9.9974C1.66797 5.39502 5.39893 1.66406 10.0013 1.66406C14.6037 1.66406 18.3346 5.39502 18.3346 9.9974Z"
                stroke="#344054" stroke-width="1.67" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
            Ajouter un compte</button>
        </div>

        <div class="tabs">
          <button :class="{ active: activeTab === 'registered' }" @click="activeTab = 'registered'">
            Comptes détectés {{ registeredComptesLocal.length }}
          </button>
          <button :class="{ active: activeTab === 'unregistered' }" @click="activeTab = 'unregistered'">
            Comptes inexistants {{ unregisteredComptesLocal.length }}
          </button>
        </div>

        <div v-if="activeTab === 'registered'">
          <table>
            <thead>
              <tr>
                <th>Compte</th>
                <th>Adresse e-mail ou numéro associé</th>
                <th>Action sur le compte</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(compte, index) in filteredRegisteredComptes" :key="index">
                <td>
                  <div class="demarches">
                    <img :src="compte.logo" @error="setDefaultLogo($event)" alt="logo" class="compte-logo" />
                    <div>
                      <div class="site-name">{{ compte.nom }}</div>
                      <div class="compte-type">{{ compte.type }}</div>
                    </div>
                  </div>
                </td>
                <td style="min-width: 200px;" class="adresse-email">{{ formatPhoneNumber(compte.email) }}</td>
                <td>
                  <div style="display: flex; flex: 1" :class="[status[compte.action]]">
                    <select v-model="compte.action" :class="compte.action">
                      <option value="Suppression">Suppression du compte</option>
                      <option value="Conservation">Conservation du compte</option>
                      <option v-if="isTransformable(compte.nom)" value="Transformation">Transformation en compte commémoratif</option>
                    </select>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div v-if="activeTab === 'unregistered'">
          <table>
            <thead>
              <tr>
                <th>Compte</th>
                <th>Adresse e-mail ou numéro associé</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(compte, index) in filteredUnregisteredComptes" :key="index">
                <td class="compte-container">
                  <img :src="compte.logo" @error="setDefaultLogo($event)" alt="logo" class="compte-logo" />
                  <div>
                    <div class="site-name">{{ compte.nom }}</div>
                    <div class="compte-type">{{ compte.type }}</div>
                  </div>
                </td>
                <td class="adresse-email">{{ formatPhoneNumber(compte.email) }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div v-else-if="localDemarches.length === 0">
        <h2>Récapitulatif des démarches</h2>
        <div class="no-demarches">
          <img src="../assets/Frame.png" alt="Aucune démarche trouvée" />
          <p class="accune-header">Aucune démarche trouvée</p>
          <p class="commencer-header">Commencez par lancer un scan</p>
        </div>
      </div>
    </div>

    <div v-if="showAddAccountPopupFlag">
      <AddAccountPopup :show="showAddAccountPopupFlag" :accounts="accounts" @close="closeAddAccountPopup"
        @next="showAccountDetailsPopup" />
    </div>

    <div v-if="showAccountDetailsPopupFlag">
      <AccountDetailsPopup :show="showAccountDetailsPopupFlag" :account="selectedAccount"
        @close="closeAccountDetailsPopup" @add="addNewAccount" @back="backToAddAccountPopup" />
    </div>
  </div>
</template>

<script>
import AddAccountPopup from './AddAccountPopup.vue';
import AccountDetailsPopup from './AccountDetailsPopup.vue';

export default {
  name: 'DiagnosticDiv',
  props: {
    registeredComptes: {
      type: Array,
      required: true
    },
    unregisteredComptes: {
      type: Array,
      required: true
    },
    dossierState: String,
    defuntName: String,
    fetchSiteMappings: Function,
    siteMappings: Array,
    siteMappingsReady: Boolean,
    demarches: Array,
    isLoadingDemarches: Boolean,
    accounts: Array
  },
  components: {
    AddAccountPopup,
    AccountDetailsPopup
  },
  data() {
    return {
      activeTab: 'registered', // Changer 'terminees' à 'registered'
      searchQuery: '', // Changer searchRegistered et searchUnregistered à searchQuery
      searchTerminees: '',
      localDemarches: [],
      localIsLoadingDemarches: false,
      showAddAccountPopupFlag: false,
      showAccountDetailsPopupFlag: false,
      selectedAccount: null,
      registeredComptesLocal: [],
      unregisteredComptesLocal: [],
      isLoading: false, // Added this line
      totalRequests: 0, // Added this line
      completedRequests: 0, // Added this line
      status: {
        Transformation: 'transformation',
        Suppression: 'suppression',
        Conservation: 'conservation'
      }
    };
  },
  computed: {
    filteredRegisteredComptes() {
      return this.registeredComptesLocal.filter(compte => compte.nom && compte.nom.toLowerCase().includes(this.searchQuery.toLowerCase()));
    },
    filteredUnregisteredComptes() {
      return this.unregisteredComptesLocal.filter(compte => compte.nom && compte.nom.toLowerCase().includes(this.searchQuery.toLowerCase()));
    },
    fullName() {
      return this.defuntName;
    }
  },
  watch: {
    registeredComptes: {
      immediate: true,
      handler(newVal) {
        console.log('registeredComptes:', newVal);
        this.registeredComptesLocal = Array.isArray(newVal) ? newVal.map(compte => ({
          ...compte,
          nom: compte.nom || 'Compte inconnu'
        })) : [];
      }
    },
    unregisteredComptes: {
      immediate: true,
      handler(newVal) {
        console.log('unregisteredComptes:', newVal);
        this.unregisteredComptesLocal = Array.isArray(newVal) ? newVal.map(compte => ({
          ...compte,
          nom: compte.nom || 'Compte inconnu'
        })) : [];
      }
    },
    demarches: {
      immediate: true,
      handler(newVal) {
        console.log('demarches:', newVal);
        this.localDemarches = Array.isArray(newVal) ? newVal : [];
      }
    }
  },
  methods: {
    async mounted() {
      await this.fetchSiteMappings();
    },
    isTransformable(nom) {
      return ['facebook', 'instagram', 'linkedin'].includes(nom.toLowerCase());
    },
    showAddAccountPopup() {
      this.showAddAccountPopupFlag = true;
    },
    closeAddAccountPopup() {
      this.showAddAccountPopupFlag = false;
    },
    showAccountDetailsPopup(account) {
      this.selectedAccount = account;
      this.showAddAccountPopupFlag = false;
      this.showAccountDetailsPopupFlag = true;
    },
    closeAccountDetailsPopup() {
      this.showAccountDetailsPopupFlag = false;
    },
    backToAddAccountPopup() {
      this.showAddAccountPopupFlag = true;
      this.showAccountDetailsPopupFlag = false;
    },
    addNewAccount(newAccount) {
      this.registeredComptesLocal.push({
        ...newAccount,
        nom: newAccount.nom || 'Compte inconnu'
      });
      this.showAccountDetailsPopupFlag = false;
    },
    updateAccountAction(accountId, action) {
      const account = this.registeredComptesLocal.find(acc => acc.id === accountId);
      if (account) {
        account.action = action;
      }
    },
    async lancerDemarches() {
      this.isLoading = true;
      this.totalRequests = 0;
      this.completedRequests = 0;

      const registeredComptesLocal = Array.isArray(this.registeredComptesLocal) ? [...this.registeredComptesLocal] : [];
      const unregisteredComptesLocal = Array.isArray(this.unregisteredComptesLocal) ? [...this.unregisteredComptesLocal] : [];
      const allComptes = [...registeredComptesLocal, ...unregisteredComptesLocal];

      let localSiteMappings = Array.isArray(this.siteMappings) ? this.siteMappings : [];

      if (!Array.isArray(localSiteMappings)) {
        console.error('siteMappings is not an array or not defined:', localSiteMappings);
        localSiteMappings = localSiteMappings ? [localSiteMappings] : [];
      }

      const pause = (duration) => new Promise(resolve => setTimeout(resolve, duration));

      this.totalRequests = allComptes.length;

      for (let i = 0; i < allComptes.length; i += 50) {
        const batch = allComptes.slice(i, i + 50);

        const requests = [];
        for (let j = 0; j < batch.length; j += 10) {
          const subBatch = batch.slice(j, j + 10);

          const records = subBatch.map((compte) => {
            const site = localSiteMappings.find(site => site.name && typeof site.name === 'string' && site.name.toLowerCase() === compte.nom.toLowerCase());
            if (!site) {
              console.error(`Site ID not found for: ${compte.nom}`);
              return null;
            }

            return {
              fields: {
                email_compte: compte.email,
                type: compte.registered ? compte.action : null,
                état: compte.registered ? 'Détecté' : 'Aucun compte',
                Tags: compte.registered ? ['détecté'] : ['aucun compte'],
                status: "inactive",
                Dossiers: [this.$root.idDossier],
                Site: [site.id]
              }
            };
          }).filter(record => record !== null);

          const request = fetch('https://api.airtable.com/v0/appBlVsssU6PaLkuN/tbl0GixAQ9WxXEHNu', {
            method: 'POST',
            headers: {
              'Authorization': 'Bearer patMlMaIReBYnCaRI.5e610df6fb9e10bd54270417562883a99c37266a39cb43875d09231d69202ab0',
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({ records })
          }).then(response => {
            if (response.status === 429) {
              console.warn('Rate limit exceeded, waiting before retrying...');
              return pause(1000).then(() => fetch('https://api.airtable.com/v0/appBlVsssU6PaLkuN/tbl0GixAQ9WxXEHNu', {
                method: 'POST',
                headers: {
                  'Authorization': 'Bearer patMlMaIReBYnCaRI.5e610df6fb9e10bd54270417562883a99c37266a39cb43875d09231d69202ab0',
                  'Content-Type': 'application/json'
                },
                body: JSON.stringify({ records })
              }));
            }
            return response.json();
          }).then(data => {
            console.log('Démarches created:', data);
            this.completedRequests += subBatch.length;
          }).catch(error => {
            console.error('Error creating démarches:', error);
          });

          requests.push(request);
        }

        await Promise.all(requests);
        await pause(5);
      }

      this.isLoading = false;
    },
    setDefaultLogo(event) {
      event.target.src = require('../assets/defaultIcon.png'); // Chemin vers votre icône par défaut
    },
    formatPhoneNumber(phone) {
      if (/^33\d{9}$/.test(phone)) {
        return phone.replace(/^33(\d{1})(\d{2})(\d{2})(\d{2})(\d{2})$/, '0$1 $2 $3 $4 $5');
      }
      return phone;
    }
  }
};
</script>

<style scoped>
.diagnostic-container {
  padding: 19px;
  border: 1px solid #EAECF0;
  background-color: #fff;
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
  background-color: #fff;
  max-width: 1200px;
  /* Ajouter une largeur maximale pour éviter qu'il ne devienne trop large */
  margin: 0 auto;

  /* Centrer le conteneur horizontalement */
  @media (min-width: 1000px) {
    width: 758px;
  }
}

.recap-container {
  display: flex;
  column-gap: 30px;
  justify-content: space-between;
}

.recap-text {
  max-width: 502px;
}

.recap-text p {
  font-size: 14px;
  color: #667085;
}

.adresse-email {
  font-size: 14px;
  font-weight: 500;
  color: #667085;
}

.recap-button button {
  padding: 10px 20px;
  background-color: #2E90FA;
  color: white;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  width: 230px;
  font-weight: 500;
  font-family: 'Outfit', sans-serif;
  font-size: 16px;
}

.recap-button button:disabled {
  background-color: #53B1FD;
  cursor: not-allowed;
}

.recap-button button:hover:not(:disabled) {
  background-color: #1570EF;
}

.site-name {
  font-size: 14px;
  color: #101828;
}


h2 {
  font-size: 24px;
  margin-bottom: 60px;
  margin-top: 0px;
  font-weight: 500;
  /* Réduire l'épaisseur de la police */
  color: #101828;
}

.tabs {
  display: flex;
  gap: 10px;
  margin-bottom: 20px;
}

.tabs button {
  padding: 8px 16px;
  border: none;
  background-color: #f2f2f2;
  cursor: pointer;
  border-radius: 5px;
}

.tabs button.active {
  background-color: #EFF8FF !important;
  color: #2E90FA !important;
  border: 1px solid #2E90FA !important;
  font-size: 14px;
  border-radius: 6px;
}

.tabs button:hover {
  background-color: #EAECF0;
  color: #667085;
}

.actions-header {
  display: flex;
  /* justify-content: space-between; */
  margin-bottom: 20px;
}

.actions-header input {
  width: 100%;
  padding: 10px;
  padding-left: 40px;
  border: 1px solid #ddd;
  border-radius: 6px;
}

.actions-header button {
  max-width: 235px;
  padding: 12px 16px;
  margin-left: 10px;
  background-color: white;
  color: 344054;
  border: 1px solid #D0D5DD;
  border-radius: 6px;
  cursor: pointer;
  font-size: 14px;
  margin-left: 15px;
  display: flex;
  font-weight: 500;
  column-gap: 8px;
  font-family: 'Outfit', sans-serif;
}

.actions-header button:hover {
  background-color: #F9FAFB !important;
}

.accune-header {
  font-size: 22px !important;
  font-weight: 500 !important;
  color: #030424 !important;
  margin-block: 0px !important;
}

.commencer-header {
  font-size: 16px !important;
  font-weight: normal !important;
  color: #667085 !important;
  margin-block: 0px !important;
}

table {
  width: 100%;
  border-collapse: collapse;
}

th,
td {
  border-bottom: 1px solid #EAECF0;
  padding-inline: 12px;
  padding-block: 13px;
}

th {
  background-color: #F9FAFB;
  text-align: left;
  font-weight: normal;
  font-size: 12px;
  color: #667085;
}

.compte-container {
  display: flex;
  align-items: center;
  column-gap: 6px;
}

.site-name {
  font-size: 14px;
  color: #101828;
}

.adresse-email {
  font-size: 14px;
  font-weight: 500;
  color: #667085;
}

.compte-logo {
  width: 30px;
  height: 30px;
  margin-right: 10px;
  border-radius: 50%;
  object-fit: contain;
}

.compte-type {
  font-weight: 400 !important;
  color: #667085 !important;
  font-size: 12px !important;
}

.instructions {
  background-color: #F9FAFB;
  padding-block: 3px;
  padding-inline: 18px;
  border: 1px solid #EAECF0;
  border-radius: 5px;
  margin-bottom: 20px;
  margin-top: 6px;
}

.instructions p {
  color: #475467;
  font-weight: 400 !important;
  margin-block: 12px;
}

.instructions p::before {
  content: '';
  display: inline-block;
  height: 3px;
  width: 3px;
  border-radius: 50%;
  margin-bottom: 5px;
  margin-right: 6px;
  background-color: #000;
}

select {
  padding: 10px 20px;
  border: 1px solid #D0D5DD;
  border-radius: 6px;
  cursor: pointer;
  width: fit-content;
  font-size: 14px;
  font-weight: 500;
  font-family: 'Outfit', sans-serif;
  appearance: none; /* Supprime la flèche par défaut */
  background: url('data:image/svg+xml;utf8,<svg fill="%23000" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"><path d="M7 10l5 5 5-5z"/></svg>') no-repeat right 10px center;
  background-size: 12px 12px;
  padding-right: 30px; /* Espace pour la flèche */
}

.transformation select {
  background-color: #EFF8FF;
  color: #1570EF;
}

.suppression select {
  background-color: #FFF6ED;
  color: #EC4A0A;
}

.conservation select {
  background-color: #EFF8FF;
  color: #1570EF;
}

select:disabled {
  background-color: #f2f2f2;
  color: #999;
}

select option {
  color: #000;
}

.demarches {
  display: flex;
  align-items: center;
  column-gap: 6px;
  width: 152px;
}

.no-demarches {
  text-align: center;
  margin-top: 20px;
}

.no-demarches img {
  width: 150px;
  height: auto;
  margin-bottom: 14px;
}

.no-demarches p {
  font-size: 16px;
  color: #333;
}

.icon {
  position: absolute;
  top: 26%;
  left: 14px;
}

.progress-bar {
  width: 100%;
  background-color: #f3f3f3;
  border-radius: 5px;
  overflow: hidden;
  height: 10px;
  margin-top: 5px;
}

.progress {
  height: 100%;
  background-color: #1570EF;
  transition: width 0.4s ease;
}

.loading-button {
  background-color: white !important;
}
</style>