<template>
  <div class="suivi-demarches">
    <div class="demarches-container">
      <div class="suivi-container">
        <div class="suivi-text">
          <h2>Suivi des démarches</h2>
          <p>Récapitulatif de toutes les actions qui sont menées sur les comptes du défunt</p>
        </div>
        <div v-if="dossierState === 'Prévalidation' || dossierState === 'Réalisation des démarches'" class="stopper">
          <button @click="stopperDemarches" :disabled="scanEnCours">
            Stopper les démarches
          </button>
        </div>
        <div v-if="dossierState === 'Stoppé'" class="stopped-label">
          <img src="@/assets/info.png" alt="info" style="width: 20px; height: 20px; margin-right: 8px;" />
          <span>Démarches stoppées</span>
        </div>
      </div>
      <div class="suivant-input" style="position: relative;">
        <input type="text" v-model="searchQuery" placeholder="Recherchez un compte" class="search-input" />
        <div class="icon">
          <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M17.5 17.5L13.875 13.875M15.8333 9.16667C15.8333 12.8486 12.8486 15.8333 9.16667 15.8333C5.48477 15.8333 2.5 12.8486 2.5 9.16667C2.5 5.48477 5.48477 2.5 9.16667 2.5C12.8486 2.5 15.8333 5.48477 15.8333 9.16667Z"
              stroke="#667085" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round" />
          </svg>

        </div>
      </div>

      <div class="tabs">
        <button :class="{ active: activeTab === 'termine' }" @click="activeTab = 'termine'">
          Terminé {{ filteredTermine.length }}
        </button>
        <button :class="{ active: activeTab === 'enCours' }" @click="activeTab = 'enCours'">
          {{ enCoursLabel }} {{ filteredEnCours.length }}
        </button>
        <button :class="{ active: activeTab === 'aucunCompte' }" @click="activeTab = 'aucunCompte'">
          Comptes inexistants {{ filteredAucunCompte.length }}
        </button>
      </div>
    </div>

    <table>
      <thead>
        <tr>
          <th>Compte</th>
          <th>Adresse e-mail ou numéro associé</th>
          <th v-if="activeTab !== 'aucunCompte'">Action sur le compte</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(demarche, index) in filteredDemarches" :key="index">
          <td class="compte-contianer">
            <img :src="demarche.site_logo" @error="setDefaultLogo($event)" alt="logo" class="compte-logo" />
            <div>
              <div class="site-name">{{ demarche.site_name }}</div>
              <div class="compte-type">{{ Array.isArray(demarche.site_type) ? demarche.site_type.join(', ') :
            demarche.site_type }}</div>
            </div>
          </td>
          <td class="adresse-email">{{ formatContactInfo(demarche.email_compte) }}</td>
          <td v-if="activeTab !== 'aucunCompte'">
            <span class="action" :class="[{ 'action-button': true, 'completed': demarche.type !== '-' }, status[demarche.type]]">
              {{ demarche.type === '-' ? 'Aucun compte' : (demarche.type === 'Suppression' ? 'Suppression du compte' : (demarche.type === 'Transformation' ? 'Transformation en compte commémoratif' : (demarche.type === 'Conservation' ? 'Conservation du compte' : demarche.type))) }}
            </span>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  name: 'SuiviDemarches',
  props: {
    demarches: {
      type: Array,
      required: true
    },
    dossierState: {
      type: String,
      required: true
    },
    scanEnCours: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      searchQuery: '',
      activeTab: 'termine',
      status:{
        Transformation: 'transformation',
        Suppression: 'suppression',
        Conservation: 'conservation'
      }
    };
  },
  computed: {
    enCoursLabel() {
      return this.dossierState === 'Stoppé' ? 'Stoppé' : 'En cours';
    },
    filteredDemarches() {
      const query = this.searchQuery.toLowerCase();
      const demarches = this.activeTab === 'termine' ? this.filteredTermine : (this.activeTab === 'enCours' ? this.filteredEnCours : this.filteredAucunCompte);
      return demarches.filter(demarche =>
        demarche.site_name.toLowerCase().includes(query) ||
        demarche.email_compte.toLowerCase().includes(query)
      );
    },
    filteredTermine() {
      return this.demarches.filter(demarche =>
        ['Terminé', 'Abandon', 'Non trouvé'].includes(demarche.état) ||
        demarche.tags.some(tag => ['terminé', 'non trouvé', 'indisponible', 'abandon', 'abandon famille', 'plainte cnil déposée'].includes(tag))
      );
    },
    filteredEnCours() {
      return this.demarches.filter(demarche =>
        !['Terminé', 'Abandon', 'Aucun compte', 'Non trouvé'].includes(demarche.état) ||
        !demarche.tags.some(tag => ['terminé', 'non trouvé', 'indisponible', 'abandon', 'abandon famille', 'plainte cnil déposée', 'aucun compte'].includes(tag))
      );
    },
    filteredAucunCompte() {
      return this.demarches.filter(demarche =>
        demarche.état === 'Aucun compte' || 
        demarche.tags.some(tag => ['aucun compte'].includes(tag))
      );
    }
  },
  methods: {
    stopperDemarches() {
      this.$emit('stopper-demarches');
      this.demarches.forEach(demarche => {
        if (!['Terminé', 'Abandon', 'Aucun compte', 'Non trouvé'].includes(demarche.état) || 
        !demarche.tags.some(tag => ['terminé', 'non trouvé', 'indisponible', 'abandon', 'abandon famille', 'plainte cnil déposée', 'aucun compte'].includes(tag))) {
          demarche.stopped = true;
        }
      });
    },
    setDefaultLogo(event) {
      event.target.src = require('../assets/defaultIcon.png'); // Chemin vers votre icône par défaut
    },
    formatContactInfo(contactInfo) {
      if (/^33\d{9}$/.test(contactInfo)) {
        // Format the phone number to 0X XX XX XX XX
        return contactInfo.replace(/^33(\d{1})(\d{2})(\d{2})(\d{2})(\d{2})$/, '0$1 $2 $3 $4 $5');
      }
      return contactInfo; // Return the email as is
    }
  }
};
</script>

<style scoped>
.suivi-demarches {
  border: 1px solid #EAECF0;
  /* border-radius: 5px; */
  background-color: #fff;
  max-width: 800px;
  margin: 0 auto;
}

.demarches-container {
  padding: 20px;
}

h2 {
  font-size: 24px;
  margin: 0px;
  font-weight: 500;
  /* Réduire l'épaisseur de la police */
  color: #101828;
}

p {
  margin-top: 4px;
  margin-bottom: 20px;
  font-size: 16px;
  color: #667085;
}

input {
  padding: 13px 10px !important;
  padding-left: 39px !important;
}

.search-input {
  width: 100%;
  padding: 10px;
  margin-bottom: 20px;
  border: 1px solid #ddd;
  border-radius: 6px;
}

.tabs {
  display: flex;
  gap: 10px;
  margin-bottom: 20px;
}

.tabs button {
  padding: 8px 16px;
  border: none;
  background-color: #f2f2f2;
  cursor: pointer;
  border-radius: 5px;
}

.tabs button.active {
  background-color: #EFF8FF;
  color: #2E90FA;
  border: 1px solid #2E90FA;
  font-size: 14px;
  border-radius: 6px;
}

table {
  width: 100%;
  border-collapse: collapse;
}

th,
td {
  border-bottom: 1px solid #EAECF0;
  padding-inline: 24px;
  padding-block: 13px;
}

th {
  background-color: #F9FAFB;
  text-align: left;
  font-weight: normal;
  font-size: 12px;
  color: #667085;
}

.compte-contianer {
  display: flex;
  align-items: center;
  column-gap: 6px;
}

.site-name {
  font-size: 14px;
  color: #101828;
}

.adresse-email {
  font-size: 14px;
  font-weight: 500;
  color: #667085;
}

.compte-logo {
  width: 30px;
  height: 30px;
  margin-right: 10px;
  border-radius: 50%;
  object-fit: contain;
}

.compte-type {
  font-weight: 400;
  color: #667085;
  font-size: 12px;
}

.action-button {
  padding: 2px 8px;
  border-radius: 50px;
  background-color: #F8F9FC;
  color: #3E4784;
  font-size: 14px;
}

.action-button.completed {
  background-color: #EFF8FF;
  color: #1570EF;
}

.stopper button {
  padding: 10px 20px;
  color: #D92D20 !important;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  background-color: transparent;
  font-size: 16px;
  font-weight: 500;
  /* margin-top: 20px; */
}

.stopper button:disabled {
  background-color: #FEE4E2;
  cursor: not-allowed;
}

.stopper :hover:enabled {
  background-color: #FEF3F2;
}

.stopped-label {
  margin-bottom: auto;
  padding: 10px 20px;
  display: flex;
  align-items: center;
  color: #D92D20;
  font-weight: 500;
  font-size: 16px;
}

button {
  font-family: 'Outfit', sans-serif;
}

button:hover {
  background-color: #EAECF0;
  color: #667085;
}

.suivi-container {
  display: flex;
  column-gap: 30px;
  justify-content: space-between;
}

.suivi-text {
  max-width: 492px;
}

.suivant-input {
  display: flex;
  flex: 1;
}

.icon {
  position: absolute;
  top: 18%;
  left: 14px;
}

.transformation{
  background-color: #EFF8FF;
  color: #1570EF;
}

.suppression{
  background-color: #FFF6ED !important;
  color: #EC4A0A !important;
}
.conservation{
  background-color: #EFF8FF !important;
  color: #1570EF !important;
}
</style>


